/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


:root {
    --ion-color-yellow: #ff0;
    --ion-color-yellow-rgb: 255, 215, 0;
    --ion-color-yellow-contrast: #000000;
    --ion-color-yellow-contrast-rgb: 0, 0, 0;
    --ion-color-yellow-shade: rgb(241, 241, 4);
    --ion-color-yellow-tint: rgb(235, 235, 0);
    --ion-color-black: #000;
    --ion-color-black-rgb: 255, 215, 0;
    --ion-color-black-contrast: #fff;
    --ion-color-black-contrast-rgb: 0, 0, 0;
    --ion-color-black-shade: #000;
    --ion-color-black-tint: #000;

    --ion-color-darkYellow: linear-gradient(to bottom,#ffec64 5%,#ffab23 100%);
    --ion-color-darkYellow-rgb: 255, 215, 0;
    --ion-color-darkYellow-contrast: #000;
    --ion-color-darkYellow-contrast-rgb: 0, 0, 0;
    --ion-color-darkYellow-shade: linear-gradient(to bottom,#ffec64 5%,#ffab23 100%);
    --ion-color-darkYellow-tint: linear-gradient(to bottom,#ffec64 5%,#ffab23 100%);
}

input.form-control.validate-error.ng-invalid.ng-touched, input.form-control.validate-error.ng-invalid.ng-dirty, .form-submitted input.form-control.validate-error.ng-invalid{
    box-shadow: none !important;
}
.md-form input[type=text]:focus:not([readonly]), .md-form input[type=password]:focus:not([readonly]), .md-form input[type=email]:focus:not([readonly]), .md-form input[type=url]:focus:not([readonly]), .md-form input[type=time]:focus:not([readonly]), .md-form input[type=date]:focus:not([readonly]), .md-form input[type=datetime-local]:focus:not([readonly]), .md-form input[type=tel]:focus:not([readonly]), .md-form input[type=number]:focus:not([readonly]), .md-form input[type=search-md]:focus:not([readonly]), .md-form input[type=search]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]){
    box-shadow: none !important;
}
input.form-control.validate-success.ng-valid.ng-dirty, input.form-control.validate-success.ng-valid.ng-touched, .form-submitted input.form-control.validate-success.ng-valid{
    box-shadow: none !important;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel]{
    padding-left: 45px !important;
    width: -webkit-fill-available !important;
}
.iti{
    display: block !important;
}
button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled){
    outline: none;
}
.md-textarea.validate-error.ng-invalid.ng-touched, .md-textarea.validate-error.ng-invalid.ng-dirty, .form-submitted .md-textarea.validate-error.ng-invalid {
    box-shadow: none !important;
}

ion-menu-button{
    color: #000 !important;
}

.ion-color-yellow{
    --ion-color-base: var(--ion-color-yellow, #ff0) !important;
    --ion-color-base-rgb: var(--ion-color-yellow-rgb, 56, 128, 255) !important;
    --ion-color-contrast: var(--ion-color-yellow-contrast, rgb(255, 255, 255)) !important;
    --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb, 255, 255, 255) !important;
    --ion-color-shade: var(--ion-color-yellow-shade, #ff0) !important;
    --ion-color-tint: var(--ion-color-yellow-tint, #ff0) !important;
}

.ion-color-black{
    --ion-color-base: var(--ion-color-black, #000) !important;
    --ion-color-base-rgb: var(--ion-color-black-rgb, 56, 128, 255) !important;
    --ion-color-contrast: var(--ion-color-black-contrast, rgb(255, 255, 255)) !important;
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb, 255, 255, 255) !important;
    --ion-color-shade: var(--ion-color-black-shade, #000) !important;
    --ion-color-tint: var(--ion-color-black-tint, #000) !important;
}

.ion-color-darkYellow{
    --ion-color-base: var(--ion-color-darkYellow,linear-gradient(to bottom,#ffec64 5%,#ffab23 100%)) !important;
    --ion-color-base-rgb: var(--ion-color-darkYellow-rgb, 56, 128, 255) !important;
    --ion-color-contrast: var(--ion-color-darkYellow-contrast, rgb(0, 0, 0)) !important;
    --ion-color-contrast-rgb: var(--ion-color-darkYellow-contrast-rgb, 255, 255, 255) !important;
    --ion-color-shade: var(--ion-color-darkYellow-shade, linear-gradient(to bottom,#ffec64 5%,#ffab23 100%)) !important;
    --ion-color-tint: var(--ion-color-darkYellow-tint, linear-gradient(to bottom,#ffec64 5%,#ffab23 100%)) !important;
}

.accordion .card.is-collapsed .card-header a .rotate-icon,.md-accordion .card .card-header a:not(.collapsed) .rotate-icon{
    margin-top: 10px;
}
.dir{
    direction: rtl !important;
}
.mdb-accordion-indicator{
    color: #000 !important;
}
mdb-accordion-item-head{
    h5{
        margin: 0;
    }
}
.white-space{
    white-space: normal;
}

mdb-accordion-item-body{
    .card-body{
        padding: 0.25rem !important;
    }
} 
.alert-wrapper{
    .alert-message{
       text-align: center !important; 
    }
}
.ionic-selectable{
    max-width: 80% !important;
}

@media (prefers-color-scheme: dark) {
    .menuListLeft,.menuListRight{
        ion-icon{
            color: #fff !important;
        }
    }
    ion-menu-button{
        color: #fff !important;
    }
    mdb-accordion-item-head{
        background: #000;
        color: #fff !important;
        .card-header{
            .mdb-accordion-indicator{
                color: #fff !important;
            }
        }
    }
    ion-reorder-group{
        .text-dark{
            color: #fff !important;
        }
        .input-wrapper{
            background: #000;
        }
    }
}
/* Fallback for older browsers or manual mode */
body.dark {
  /* Dark mode variables go here */
    .menuListLeft,.menuListRight{
        ion-icon{
            color: #fff !important;
        }
    }
    ion-menu-button{
        color: #fff !important;
    }
    mdb-accordion-item-head{
        background: #000;
        color: #fff !important;
        .card-header{
            .mdb-accordion-indicator{
                color: #fff !important;
            }
        }
    }
    ion-reorder-group{
        .text-dark{
            color: #fff !important;
        }
        .input-wrapper{
            background: #000;
        }
    }
}